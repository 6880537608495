import { EventEmitter } from "../libs/utils/EventEmitter"

export class MediaQuery extends EventEmitter {
  constructor() {
    super()
    this.mql = window.matchMedia(`(max-width: 750px)`)
    this.mql.addListener(this.checkBreakPoint.bind(this))
  }

  checkBreakPoint() {
    if (this.mql.matches) {
      this.emit("onChange", { size: "sp" })
    } else {
      this.emit("onChange", { size: "other" })
    }
  }
}

export default new MediaQuery()
