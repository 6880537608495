import gsap, { Sine, Power4 } from "gsap"
import mq from "./MediaQuery"
export class GlobalHeader {
  constructor(params) {
    this.$header = $("#global_header")
    this.params = { ...params, denseClassName: "-dense" }
    this.isSp = false
    this.timerID = null
    this.nowShowing = {}

    /** メディアクエリ変更 */
    mq.on("onChange", ({ size }) => {
      if (size === "sp") {
        /** SP */
        this.isSp = true
        // メガメニューのSPイベントを設定
        this.bindMegaMenuSp()
        // メガメニューのPCイベントを削除
        this.removeMegaMenuPc()
        // サーチエリアのSPイベントを設定
        this.bindSearchAreaSp()
      } else {
        /** PC・タブレット */
        this.isSp = false
        // メガメニューのSPイベントを削除
        this.removeMegaMenuSp()
        // メガメニューのPCイベントを設定
        this.bindMegaMenuPc()
        // サーチエリアのPCイベントを設定
        this.bindSearchAreaPc()
        //p-header__primaryNavButtonにクリックイベントを設定
        this.pnavClickToCatTop()
        //ページ内topへ戻る
        this.pageTop()
      }
    })
    mq.checkBreakPoint()

    // ナビのカレント表示
    this.navCurrent()
  }
	pageTop(){
		//ページトップへスクロール
    this.Footer = document.querySelector('.l-footer');
		const pagetop = document.querySelector('.l-footer__pageTop');
		const pagetopAnchor = pagetop.firstElementChild;

		const footerPos = window.pageYOffset + this.Footer.getBoundingClientRect().top;

		if( window.pageYOffset > 0 ){
			pagetop.classList.add('show');
		}else{
			pagetop.classList.remove('show');
		}

		if( window.pageYOffset < footerPos - window.innerHeight ){
			pagetop.classList.add('fixed');
		}else{
			pagetop.classList.remove('fixed');
		}
	};

  /** PCのみ、グロナビをクリックすると各カテゴリトップへ*/
  pnavClickToCatTop(){

    $("#nav_company").on("click", (e) => {
      window.open('/company/','_self')
    })
    $("#nav_sustainability").on("click", (e) => {
      window.open('/sustainability/','_self')
    })
    $("#nav_communication").on("click", (e) => {
      window.open('/communication/','_self')
    })
    $("#nav_research").on("click", (e) => {
      window.open('/research/','_self')
    })
    $("#nav_ir").on("click", (e) => {
      window.open('/ir/','_self')
    })
  }


  /** ナビのカレント表示 */
  navCurrent() {
    const pathnameArr = location.pathname.split("/")
    if (pathnameArr.length <= 2) return
    const key = pathnameArr[1]
    if (!key.length) return
    const $target = $(`#nav_${key}`)
    if (!$target.length) return
    // aria-current="true"をつける
    $target.attr("aria-current", true,)
  }

  /** メガメニューのSP版イベントを設定 */
  bindMegaMenuSp() {
    // メニューボタンをクリック
    let isOpen = false
    $("#nav-group").find(":focusable").attr("tabindex", "-1")
    $(".p-header__menuButton").on("click", (e) => {
      if (!this.isSp) return
      const $me = $(e.currentTarget)
      const $screen = $me.find(".p-header__menuButton__screen")
      if (isOpen) {
        $("#nav-group").removeClass("-open")
        $screen.text("メニューを開く")
        $me.attr("aria-expanded", "false")
        $("#nav-group").find(":focusable").attr("tabindex", "-1")
      } else {
        $("#nav-group").addClass("-open")
        $screen.text("メニューを閉じる")
        $me.attr("aria-expanded", "true")
        $("#nav-group").find(":focusable").removeAttr("tabindex")
      }
      isOpen = !isOpen
    })

    // 子メニューの開閉
    $(".p-header__primaryNavButton").each((idx, me) => {
      const $button = $(me)
      const $menu = $(me).next()
      let isOpenMenu = false
      $menu.find("a").attr("tabindex", "-1")
      $button.on("click", () => {
        if (isOpenMenu) {
          $button.attr("aria-expanded", "false")
          $menu.attr("aria-hidden", "true")
          $menu.find("a").attr("tabindex", "-1")
          gsap.to($menu, {
            height: 0,
            ease: Power4.easeOut,
            duration: 0.8
          })
        } else {
          $button.attr("aria-expanded", "true")
          $menu.attr("aria-hidden", "false")
          $menu.find("a").removeAttr("tabindex")
          gsap.to($menu, {
            height: "auto",
            ease: Power4.easeOut,
            duration: 0.8,
            onComplete: () => {
              $menu.css({ height: "auto" })
            }
          })
        }
        isOpenMenu = !isOpenMenu
      })
    })
  }

  /** メガメニューのSP版イベントを削除 */
  removeMegaMenuSp() {
    $("#nav-group").removeClass("-open")
    $(".p-header__menuButton").off("click")
    $(".p-header__primaryNavButton").off("click")

    $(".p-header__primaryNavButton").each((idx, me) => {
      const $button = $(me)
      const $menu = $(me).next()
      $button.off("click")
      $menu.css({ height: 0 })
    })
  }


  /** メガメニューのPC版イベントをバインドする */
  bindMegaMenuPc() {
    /** メガメニュー */
    $(".js-global-menu").each((idx, me) => {
      const $nav = $(me)
      const $btn = $nav.find(".p-header__primaryNavButton")
      if ($btn.length > 0) {
        const $menu = $nav.find(".p-headerChildMenu")

        $nav.on("mouseenter", () => {
          $btn.attr("aria-expanded", true)
          $menu.attr("aria-hidden", false)
          $menu.addClass("-active")

          // カスが残る場合があるのでこれを削除した
          for (var id in this.nowShowing) {
            if (id != $menu.attr("id") && this.nowShowing[id]) {
              this.nowShowing[id]._dp.clear()
              gsap.to($(this.nowShowing[id]._targets[0]), {height: 0, ease: Power4.easeOut, duration: 0.4})
              this.nowShowing[id] = null
            }
          }
          if (this.timerID != null) clearTimeout(this.timerID)
          this.timerID = setTimeout(() => {
            if ($menu.attr("aria-hidden") == "false") {
              this.nowShowing[$menu.attr("id")] = gsap.to($menu, {
                height: "auto",
                ease: Sine.easeOut,
                duration: 0.8,
                onComplete: () => {
                  $menu.css({ height: "auto" })
                  this.nowShowing[$menu.attr("id")] = null
                  this.timerID = null
                }
              })
            }
          }, 300)
        })

        $nav.on("mouseleave", () => {
          if (this.nowShowing[$menu.attr("id")]) {
            this.nowShowing[$menu.attr("id")]._dp.clear()
            this.nowShowing[$menu.attr("id")] = null
            this.timerID = null
          }
          $menu.removeClass("-active")
          $btn.attr("aria-expanded", false)
          $menu.attr("aria-hidden", true)
          gsap.to($menu, {
            height: 0,
            ease: Power4.easeOut,
            duration: 0.4
          })
        })
      }
    })
  }

  /** メガメニューのPC版イベントを消去する */
  removeMegaMenuPc() {
    $(".js-global-menu").each((idx, me) => {
      const $nav = $(me)
      const $menu = $nav.find(".p-headerChildMenu")
      const $btn = $nav.find(".p-header__primaryNavButton")

      // PCのイベントを外す
      $nav.off("mouseenter")
      $nav.off("mouseleave")

      $menu.removeClass("-active")
      $btn.attr("aria-expanded", false)
      $menu.attr("aria-hidden", true)
      $menu.css({ height: 0 })
    })
  }

  /** 検索ボタンのPC版イベントをバインドする */
  bindSearchAreaPc() {
    var sbox_is_open = false;
    $(document).off("click.searchArea")
    // $("#header-search").css({ height: 0 })
    $(window).scroll(function(e){
      // スクロールしたときの処理内容を記述する
      gsap.to("#header-search", {
        height: 0,
        ease: Power4.easeOut,
        duration: 0.3,
        onComplete: () => {
          $("#input_search").blur()
        }
      })
      sbox_is_open = false;
    });

    $(".p-header__searchButton")
      .on("click", () => {
        if(sbox_is_open){
          sbox_is_open = false;
          gsap.to("#header-search", {
            height: 0,
            ease: Power4.easeOut,
            duration: 0.3,
            onComplete: () => {
              $("#input_search").blur()
            }
          })
        }else{
          sbox_is_open = true;
          gsap.to("#header-search", {
            height: "auto",
            ease: Power4.easeOut,
            duration: 0.3,
            onComplete: () => {
              $("#input_search").focus()
            }
          })
        }

      });
      $(".p-header__searchArea.opened")
        .on("click", () => {
          console.log("sssssss");
          $(".p-header__searchArea").removeClass('opened');
          $(".p-header__searchArea").addClass('closed');
          gsap.to("#header-search", {
            height: 0,
            ease: Power4.easeOut,
            duration: 0.3,
            onComplete: () => {
              $("#input_search").blur()
            }
          })
        })
  }


  /** 検索ボタンのSP版イベントをバインドする */
  bindSearchAreaSp() {
    $(".p-header__searchArea").off("mouseenter")
    $(".p-header__searchArea").off("mouseleave")
    $("#header-search").css({ height: 0 })

    let isOpen = false

    // 開く
    $(".p-header__searchArea").on("click", () => {
      if (isOpen) return
      gsap.to("#header-search", {
        height: "auto",
        ease: Power4.easeOut,
        onComplete: () => {
          isOpen = !isOpen
          if (isOpen) {
            $("#input_search").focus()
          }
        }
      })
    })

    // 閉じる
    $(document).on("click.searchArea", (e) => {
      if (!$(e.target).closest("#header-search").length && isOpen) {
        gsap.to("#header-search", {
          height: 0,
          ease: Power4.easeOut,
          onComplete: () => {
            isOpen = !isOpen
          }
        })
      }
    })
  }

  /** 三点リーダーのイベントをバインドする */
  bindSubMenuEvent() {
    if (this.isSp) return
    $("#submenu_body").css({ height: 0 })
    $("#btn_submenu")
      .on("mouseenter", () => {
        gsap.to("#submenu_body", {
          height: "auto",
          ease: Power4.easeOut,
          duration: 0.3,
          onComplete: () => {
            $("#submenu_body").css({ height: "auto" })
          }
        })
      })
      .on("mouseleave", () => {
        gsap.to("#submenu_body", {
          height: 0,
          ease: Power4.easeOut,
          duration: 0.3
        })
      })
  }

  /** 三点リーダーのイベントを外す */
  removeSubMenuEvent() {
    $("#btn_submenu").off("mouseenter")
    $("#btn_submenu").off("mouseleave")
    $("#submenu_body").css({ height: "auto" })
  }

  /**
   * スクロールするとヘッダメニューにCSSクラスを付与する
   */
  dense(_scrollTop) {
    const scrollTop =
      _scrollTop === undefined ? $(window).scrollTop() : _scrollTop
    if (scrollTop > 0 && !this.isDense) {
      this.isDense = true
      this.$header.addClass(this.params.denseClassName)
      this.bindSubMenuEvent()
      return
    }
    //ページ内トップへ戻る
    this.pageTop()

    if (scrollTop <= 0 && this.isDense) {
      this.isDense = false
      this.$header.removeClass(this.params.denseClassName)
      this.removeSubMenuEvent()
    }
  }
}
