import Stickyfill from "stickyfilljs"
import { gsap } from "gsap/all"
import ScrollTrigger from "@terwanerik/scrolltrigger"

export class CompanyHistory {
  constructor() {
    const $currentYear = $("#current_year")
    const $yearTitle = $(".p-companyBlock13__header1Title1")
    const $indicator = $(".p-companyBlock13__indicator")

    // IE11だけJSでSticky対応
    const elements = $(".p-companyBlock13__header1")
    Stickyfill.add(elements)

    // インジケーターの生成
    let indicatorHTML = ""
    for (let i = 0; i < $(".p-companyBlock13__unit1").length; i++) {
      indicatorHTML += `
<div class="p-companyBlock13__indicator__dot js-base-dot">
  <svg width="6" height="6" viewBox="0 0 6 6" focusable="false">
    <circle cx="3" cy="3" r="3"/>
  </svg>
</div>`
    }
    $("#indicator_base").html(indicatorHTML)

    // $("#history_title").sticky({ topSpacing: 100 })

    const updateIndicator = (index) => {
      const height = index * 43 + index * 3
      $("#indicator_active").css({ height: `${height}px` })
      setTimeout(() => {
        $(".js-base-dot").each((idx, el) => {
          if (idx < index) {
            $(el).addClass("-active")
          } else {
            $(el).removeClass("-active")
          }
        })
      }, 100)
    }

    const changeYearLabel = (year) => {
      $currentYear.text(year)
    }

    const trigger = new ScrollTrigger({
      trigger: {
        offset: {
          viewport: {
            y: 0.5
          }
        },
        toggle: {
          callback: {
            in: (obj) => {
              const $el = $(obj.element)
              changeYearLabel($el.data("year"))
              updateIndicator($("[data-trigger]").index(obj.element))
            }
          }
        }
      }
    })

    trigger.add("[data-trigger]")

    gsap.ticker.add(() => {
      if ($currentYear.text().length === 0) {
        $yearTitle.removeClass("-active")
        $indicator.removeClass("-active")
      } else {
        $yearTitle.addClass("-active")
        $indicator.addClass("-active")
      }
    })
  }
}
