/** ニュース記事 */
export class Post {
  constructor() {
    this.$post = $("#wp-post")
    this.table()
    this.wsywyg_img_styling()
  }

  /** テーブル */
  table() {
    this.$post.find("table").each((idx, table) => {
      const $table = $(table)
      $table.wrap("<div class='p-post__table'></div>")
    })
  }
  wsywyg_img_styling(){
    let wsywyg_img_htmlcol = document.querySelectorAll(".wysiwyg img");
    Array.prototype.forEach.call(wsywyg_img_htmlcol, function(imgtag) {
      let width = imgtag.getAttribute("width");
      imgtag.style.width=width + "px";
      });
  }
}
