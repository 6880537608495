export class PrintBtn {
  constructor() {
    if (document.getElementById("printButton") === null) {
       return;
    }
    // 印刷ボタンを取得する
    var printButton = document.getElementById("printButton");
    // 印刷ボタンがクリックされたときの処理を定義する
    printButton.onclick = function() {
      // 現在表示されているページを印刷する
      window.print();
    }
    if(document.querySelector('.p-newsDetail-release')){
      document.querySelector('html').classList.add('target_print');
    }
    if(document.querySelector('.p-newsDetail-info')){
      document.querySelector('html').classList.add('target_print');
    }
  }
}
