import { gsap, ScrollTrigger } from "gsap/all"

export class HeadingAnim {
  constructor() {
    $(".c-pageHeader__inner").each((idx, el) => {
      const tl = gsap.timeline()
      tl.from(el, { x: "-=50", duration: 1 })
      tl.to(el, { opacity: 1, duration: 1 }, "-=0.5")
      tl.call(
        () => {
          $(el).addClass("-active")
        },
        null,
        "-=0.5"
      )

      ScrollTrigger.create({
        trigger: el,
        // デバッグ用
        markers: false,
        start: "50% 70%",
        once: "true",
        animation: tl
      })
    })
  }
}
