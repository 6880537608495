export class Accordion {
  constructor() {}

  setup() {
    $(".js-accordion").accordion({
      header: ".js-accordion-head",
      collapsible: true,
      active: false
    })
    /**
     * アコーディオンへリンクする場合、かつ.js-accordion--deeplinkを付与時は、展開状態とする
     * 例：/sustainability/environment/#m-longterm-goals
     */
    let req_hash = document.location.hash
    if (req_hash) {
      let selector_open = req_hash + ".js-accordion--deeplink"
      if ($(selector_open).length > 0) {
        $(req_hash).accordion({ active: 0 })
      }
    }
    /**
     * アコーディオンをデフォで開いておく場合
     */
    if ($(".--default-op")) {
      $(".--default-op").accordion({ active: 0 })
    }
  }

  destroy() {
    $(".js-accordion").accordion("destroy")
  }

  destroyAndSetup() {
    if ($(".js-accordion").hasClass("ui-accordion")) {
      this.destroy()
    }
    window.requestAnimationFrame(() => {
      this.setup()
    })
  }
}
