import { EventEmitter } from "./utils/EventEmitter"

export class Tab extends EventEmitter {
  constructor() {
    super()

    this.tabOptions = {}

    /** シンプルなタブ */
    $(".js-tabs").tabs()

    /** タブとセレクトボックスのコンビ */
    $(".js-tab-and-select").each((idx, me) => {
      const $me = $(me)
      const $tabs = $me.find(".js-tab-and-selector__tab").find("a")
      const $select = $me.find(".js-tab-and-selector__select")
      const $contents = $tabs.map((idx, me) => {
        return $(me.hash)
      })
      let selectedId = null

      const changeTab = () => {
        this.emit("changeTab")

        // タブのクラス変更
        $tabs.each((idx, tab) => {
          if (tab.hash === `#${selectedId}`) {
            $(tab).addClass("-active")
            $(tab).parent("li").attr({
              "aria-selected": "true",
              "aria-expanded": "true"
            })
          } else {
            $(tab).removeClass("-active")
            $(tab).parent("li").attr({
              "aria-selected": "false",
              "aria-expanded": "false"
            })
          }
        })

        // セレクトボックスの選択
        $select.find("option").each((idx, opt) => {
          if ($(opt).attr("value") === selectedId) {
            $(opt).attr("selected", true)
          } else {
            $(opt).attr("selected", false)
          }
        })

        // ボディの表示変更
        $contents.each((idx, $el) => {
          if ($el[0].id === selectedId) {
            $el.show()
          } else {
            $el.hide()
          }
        })
      }

      $contents.each((idx, $el) => {
        if (idx === 0) {
          selectedId = $el[0].id
        } else {
          $el.hide()
        }
      })

      $tabs.on("click", (e) => {
        e.preventDefault()
        const me = e.currentTarget
        selectedId = me.hash.substr(1)
        changeTab()
      })

      // セレクトボックス
      $select.on("change", () => {
        selectedId = $select.val()
        changeTab()
      })

      $me.css({ visibility: "visible" })
    })
  }
}
