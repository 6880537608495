import { gsap, ScrollTrigger } from "gsap/all"

export class Home {
  constructor() {
    if ($("#page-home").length) {
      this.init()
    }
  }

  init() {
    /** 見出しのアニメーション */
    $(".p-topInfo__headingText, .c-heading").each((idx, el) => {
      const tl = gsap.timeline()
      tl.from(el, { y: "-=50", duration: 1 })
      tl.from(el, { opacity: 0, duration: 1 }, "-=0.5")
      tl.call(
        () => {
          $(el).addClass("-active")
        },
        null,
        "-=0.7"
      )

      ScrollTrigger.create({
        trigger: el,
        // デバッグ用
        markers: false,
        start: "50% 100%",
        once: "true",
        animation: tl
      })
    })
  }
}
