import { gsap } from "gsap"
import { Power4 } from "gsap/gsap-core"
import { EventEmitter } from "./utils/EventEmitter"

export class Scroller extends EventEmitter {
  static scrollTo({ duration = 1, y, offsetY = 0, ease = Power4.easeOut }) {
    gsap.to(window, {
      duration,
      scrollTo: { y, offsetY },
      ease
    })
  }

  static bindClickScrollTo(el) {
    $(document).on("click", el, (e) => {
      e.preventDefault()
      const me = e.currentTarget
      const $me = $(me)
      const hash = me.hash
      if (me.tagName !== "A") {
        throw new Error("scrollToはAタグに設定してください")
      }
      if (hash === undefined) {
        throw new Error("スクロール先を設定してください")
      }
      const target = $(hash).length <= 0 ? 0 : me.hash
      const duration = parseInt($me.data("duration"), 10) || 1
      let offsetY = parseInt($me.data("offset-y"), 10) || 0
      const isHeader = !!$me.data("header")

      if (isHeader) {
        offsetY = $("#global_header").height()
      }

      if ($(hash).length > 0) {
        // URLにハッシュを追加
        history.pushState(null, null, target)
      }

      Scroller.scrollTo({ duration, offsetY, y: target })
    })
  }

  /** URLにハッシュがついているとき */
  static initializeTo() {
    const hash = location.hash
    if (!hash) return
    const $target = $(hash)
    if ($target.length === 0) return
    const offsetY_val = $("#global_header").height() * 1.5
    Scroller.scrollTo({
      duration: 0.5,
      offsetY: offsetY_val,
      y: hash
    })
  }

  constructor() {
    super()
    // スクロールイベントを間引くミリ秒
    this.threshold = 100

    $(window).on(
      "scroll",
      $.throttle(100, () => {
        this.emit("scroll", { scrollTop: $(window).scrollTop() })
      })
    )
  }
}
