export class ResultSearch {
  constructor() {
    if ($("#result").length) {
      this.search()
      this.checkToggle()
    }
  }
  // 検索機能
  search() {
    $(function() {
      // 検索ボタンクリック時の処理
      var year
      var field
      var type
      $("#search-btn").click(function() {
        // 選択された条件を取得
        year = $('[name="year"]:checked').map(function() {
            return $(this).val()
          }).get()
        field = $('[name="field"]:checked').map(function() {
            return $(this).val()
          }).get()
        type = $('[name="type"]:checked').val()
    
        // 種別のセレクタを取得
        var typeSelecter = ""
        if (type == "paper") {
          typeSelecter = '[data-type="paper"]'
        } else if (type == "conference") {
          typeSelecter = '[data-type="conference"]'
        }

        // 年代、分野のセレクタを作成
        var selectorString = ""
        var selectorStringArr

        // 各項目の選択がある場合とない場合でセレクタを出し分ける
        if (year.length > 0 && field.length > 0) {
          year.forEach(function(yearEl) {
            // 全てのフィールドについてループ
            field.forEach(function(fieldEl) {
              // CSSセレクタの文字列を生成
              selectorString += '.item[data-year="' + yearEl + '"][data-field~="' + fieldEl + '"],'
            })
          })
        } else if (year.length > 0 && field.length == 0){
          year.forEach(function(yearEl) {
            // 全てのフィールドについてループ
            selectorString += '.item[data-year="' + yearEl + '"],'
          })
        } else if (year.length == 0 && field.length > 0) {
          field.forEach(function(fieldEl) {
            // CSSセレクタの文字列を生成
            selectorString += '.item[data-field~="' + fieldEl + '"],'
          })
        } else {
          selectorString = ".item"
        }
        if (selectorString === ".item") {
          selectorString += typeSelecter
        } else {
          selectorString = selectorString.slice(0, -1);
          selectorStringArr = selectorString.split(",")
          for (var i = 0; i < selectorStringArr.length; i++) {
            selectorStringArr[i] = selectorStringArr[i] + typeSelecter
          }
          selectorString = selectorStringArr.join(",")
        }
        // console.log(selectorString)
        var items = $(selectorString)
        $(".item").hide()
        items.show()
      })
    })
  }
  // checkとclassを連動
  checkToggle() {
    this.checkBox()
    this.radioBox()
  }
  checkBox() {
    $('input[type="checkbox"]').change(function() {
      if ($(this).is(":checked")) {
        $(this).parent().addClass("checked")
      } else {
        $(this).parent().removeClass("checked")
      }
    })
  }
  radioBox() {
    $('input[type="radio"]').change(function() {
      $('input[type="radio"]').parent("label").removeClass("checked")
      $('input[type="radio"]:checked').parent("label").addClass("checked")
    })
    // $(function() {
    //   $('input[type="radio"]:checked').parent('label').addClass("checked");
    // })
  }
}
